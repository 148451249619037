import {
  SET_PROPS_SELECT_ACQUIRING_ORGANIZATION,
  CHANGE_VALIDATE_ACQUIRING_ORGANIZATION,
  SELECT_ACQUIRING_ORGANIZATIONS,
  ISVALIDATE_ACQUIRING_ORGANIZATION,
  SET_PROPS_ACQUIRING_EDIT_ORGANIZATION,
  CLEAR_ACQUIRING_ORGANIZATION,
} from "domain/types/organizations";

const initialState = {
  acquiring: {
    id: 1,
    acquiringId: 1,
    paymentToken: "",
    paymentLogin: "",
    paymentPassword: "",
    paymentCallback: "",
    comment: "",
    extParams: {}
  },
  acquiringShow: false,
  select: null,
  isValid: false,
  required: false,
};

export default function pagination(state = initialState, action) {
  switch (action.type) {
    case SELECT_ACQUIRING_ORGANIZATIONS:
      return {
        ...state,
        select: action.payload,
      };

    case CHANGE_VALIDATE_ACQUIRING_ORGANIZATION:
      return {
        ...state,
        required: action.payload,
      };
    case ISVALIDATE_ACQUIRING_ORGANIZATION:
      return {
        ...state,
        isValid: action.payload,
      };

    case SET_PROPS_ACQUIRING_EDIT_ORGANIZATION:
      return {
        ...state,
        acquiring: action.payload,
      };
    case SET_PROPS_SELECT_ACQUIRING_ORGANIZATION:
      return {
        ...state,
        select: action.payload,
      };
    case CLEAR_ACQUIRING_ORGANIZATION:
      return {
        ...state,
        acquiring: {
          id: 1,
          acquiringId: 1,
          paymentToken: "",
          paymentLogin: "",
          paymentPassword: "",
          paymentCallback: "",
          comment: "",
          extParams: {}
        },
      };

    default:
      return state;
  }
}
