import React from "react";
import { connect } from "react-redux";
import {
  setPropAcquiring,
  setPropAcquiringEdit,
} from "domain/actions/organizations/acquiring";
import { PasswordField } from "components/forms/fields";

function Password(props) {
  const setProp = !props.select
    ? props.setPropAcquiring
    : props.setPropAcquiringEdit;

  return (
    <PasswordField
      className={props.className}
      label="Client secret(Секретный ключ)"
      onBlur={(e) => {
        setProp("paymentPassword", e.target.value);
      }}
      defaultValue={props.value}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgAcquiring.acquiring.paymentPassword,
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps, {
  setPropAcquiring,
  setPropAcquiringEdit,
})(Password);
