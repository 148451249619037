import {
  SET_SHOW_ACQUIRING_ORGANIZATION,
  SET_SHOW_ACQUIRING_BIND_CASHBOX,
  SET_PROPS_ACQUIRING_EDIT_ORGANIZATION,
  SET_PROPS_SELECT_ACQUIRING_ORGANIZATION,
  SELECT_ACQUIRING_ORGANIZATIONS,
  SELECT_ORGANIZATIONS,
  CLEAR_ACQUIRING_ORGANIZATION,
  SET_SHOW_ACQUIRING_LOCAL_ORGANIZATION,
  SET_SHOW_CONNECT_ORGANIZATION,
  SET_SHOW_CONNECT_EDIT_ORGANIZATION,
} from "domain/types/organizations";
import { SHOW_SUCCESS } from "domain/actionTypes";
import { clearObjectFromNull } from "controllers/clearObjectFromNull";
import { ApiController } from "domain/controllers";

import ClientController from "utils/Client";

const Client = new ClientController();
const api = new ApiController();

export async function getOrgById(id) {
  const response = await Client.get(`/org/info/${id}`);

  const result = response.data.result;
  const data = result.organization;
  data.acceptLicenses = result.acceptLicenses ? result.acceptLicenses : [];

  return data;
}

export function getLinkConnect(id) {
  return async function thunk(dispatch, getState) {
    const response = await api.get(`/accept/link/${id}`);
    return response.data;
  };
}

export function deleteConnect(id) {
  return async function thunk(dispatch, getState) {
    return await api.delete(`/accept/${id}`);
  };
}

export function getAcquiringLocal(orgId) {
  return async function thunk(dispatch, getState) {
    const response = await api.get(`/localOrgAcq`, { organizationId: orgId });
    return response.data;
  };
}

export function postAcquiringLocal(data) {
  return async function thunk(dispatch, getState) {
    const _data = {
      organizationId: getState().orgCreate.select.id,
      title: data.title,
      settingsData: {
        acqType: data.acqType.name.toUpperCase(),
        login: data.login,
        secretKey: data.secretKey,
        accessCode: data.accessCode,
      },
    };

    await api.post(`/localOrgAcq`, _data);

    // const org = await getOrgById(_data.organizationId)

    clearFull(dispatch);
  };
}

export function postAcquiring() {
  return async function thunk(dispatch, getState) {
    const data = { ...getState().orgAcquiring.acquiring };
    const acquiring = getState().requisites.acquiring;
    const orgID = getState().orgCreate.select.id;

    const _filter = acquiring.filter((acq) => acq.id === data.acquiringId);
    if (_filter.length) data.extParams.acqName = _filter[0].name;

    data.organizationId = orgID;
    delete data.id;

    clearObjectFromNull(data);

    await Client.post(`/orgAcq`, data)
      .then(async (r) => {
        await Client.get(`/org/info/${orgID}`).then((response) => {
          const result = response.data.result;
          const data = result.organization;
          data.acceptLicenses = result.acceptLicenses
            ? result.acceptLicenses
            : [];

          dispatch({
            type: SELECT_ORGANIZATIONS,
            payload: data,
          });
        });
        clearFull(dispatch);
      })
      .catch((e) => console.error(e));
  };
}

export function putAcquiringLocal(data) {
  return async function thunk(dispatch, getState) {
    const _data = {
      organizationId: getState().orgCreate.select.id,
      title: data.title,
      settingsData: {
        acqType: data.acqType.name.toUpperCase(),
        login: data.login,
        secretKey: data.secretKey,
        accessCode: data.accessCode,
      },
    };

    await api.put(`/localOrgAcq/${data.id}`, _data);

    clearFull(dispatch);
  };
}

export function putAcquiring() {
  return async function thunk(dispatch, getState) {
    const data = { ...getState().orgAcquiring.select };
    const acquiring = getState().requisites.acquiring;

    const _filter = acquiring.filter((acq) => acq.id === data.acquiringId);
    if (_filter.length) data.extParams.acqName = _filter[0].name;

    await Client.put(`/orgAcq/${data.id}`, data)
      .then((r) => {
        refreshAcquiring(data, getState, dispatch);
        clearFull(dispatch);
      })
      .catch((e) => console.error(e));
  };
}

export function deleteAcquiringLocal(acqId) {
  return async function thunk(dispatch, getState) {
    await Client.delete(`/localOrgAcq/${acqId}`);

    clearFull(dispatch);
  };
}

export function deleteAcquiring() {
  return async function thunk(dispatch, getState) {
    const data = { ...getState().orgAcquiring.select };

    await Client.delete(`/orgAcq/${data.id}`)
      .then((r) => {
        refreshDeleteAcquiring(data, getState, dispatch);
        clearFull(dispatch);
      })
      .catch((e) => console.error(e));
  };
}

export function uploadCert(file) {
  return async function thunk(dispatch, getState) {
    const data = { ...getState().orgAcquiring.select };

    const fd = new FormData();
    fd.append("cert", file, file.name);

    await Client.post(`/orgAcq/attachFile/${data.id}`, fd).then((r) => {
      if (r.data && r.data.message)
        dispatch({
          type: SHOW_SUCCESS,
          payload: r.data.message,
        });
      const item = { ...getState().orgAcquiring.select };
      item.extParams.certId = true;
      dispatch({
        type: SET_PROPS_SELECT_ACQUIRING_ORGANIZATION,
        payload: item,
      });
    });
  };
}

export function handleBindCashbox(data) {
  return async function thunk(dispatch, getState) {
    const org = { ...getState().orgCreate.select };

    await Client.post(`/accept/new/${org.id}`, data).then(async (r) => {
      await Client.get(`/org/info/${org.id}`).then((response) => {
        const result = response.data.result;
        const data = result.organization;
        data.acceptLicenses = result.acceptLicenses
          ? result.acceptLicenses
          : [];
        dispatch({
          type: SELECT_ORGANIZATIONS,
          payload: data,
        });
      });
      clearFull(dispatch);

      dispatch({ type: SET_SHOW_ACQUIRING_BIND_CASHBOX, payload: false });
      dispatch({
        type: SHOW_SUCCESS,
        payload: "Эквайринг связан с выбранной кассой",
      });
    });
  };
}

export function selectedAcquiring(acquiring) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SELECT_ACQUIRING_ORGANIZATIONS, payload: acquiring });
  };
}

export function setPropAcquiring(key, value, key2) {
  return async function thunk(dispatch, getState) {
    const item = { ...getState().orgAcquiring.acquiring };

    if (key2) item[key][key2] = value;
    else item[key] = value;

    dispatch({ type: SET_PROPS_ACQUIRING_EDIT_ORGANIZATION, payload: item });
  };
}

export function setPropAcquiringEdit(key, value, key2) {
  return async function thunk(dispatch, getState) {
    const item = { ...getState().orgAcquiring.select };

    if (key2) item[key][key2] = value;
    else item[key] = value;

    dispatch({ type: SET_PROPS_SELECT_ACQUIRING_ORGANIZATION, payload: item });
  };
}

export function handleShowAcquiring(isShow) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_SHOW_ACQUIRING_ORGANIZATION, payload: isShow });

    if (!isShow) clearFull(dispatch);
  };
}

export function handleShowAcquiringLocal(isShow) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_SHOW_ACQUIRING_LOCAL_ORGANIZATION, payload: isShow });

    if (!isShow) clearFull(dispatch);
  };
}

export function handleShowConnectEdit(isShow) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_SHOW_CONNECT_EDIT_ORGANIZATION, payload: isShow });
  };
}

export function handleShowConnect(isShow) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_SHOW_CONNECT_ORGANIZATION, payload: isShow });
  };
}

export function handleShowBindCashbox(isShow) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_SHOW_ACQUIRING_BIND_CASHBOX, payload: isShow });

    if (!isShow) clearFull(dispatch);
  };
}

function clearFull(dispatch) {
  dispatch({ type: SET_SHOW_ACQUIRING_ORGANIZATION, payload: false });
  dispatch({ type: SET_SHOW_ACQUIRING_LOCAL_ORGANIZATION, payload: false });
  dispatch({ type: CLEAR_ACQUIRING_ORGANIZATION });
  dispatch({ type: SELECT_ACQUIRING_ORGANIZATIONS, payload: false });
}

// async function reloadOrg(acquiring, getState, dispatch) {
//   const item = getState().orgCreate.select;
//   item.organizationAcquiring = [...item.organizationAcquiring, acquiring];

//   dispatch({ type: SELECT_ORGANIZATIONS, payload: item });
// }

async function refreshAcquiring(acquiring, getState, dispatch) {
  const item = getState().orgCreate.select;
  const index = item.organizationAcquiring
    .map((i) => i.id)
    .indexOf(acquiring.id);

  item.organizationAcquiring.splice(index, 1, acquiring);

  dispatch({ type: SELECT_ORGANIZATIONS, payload: item });
}

async function refreshDeleteAcquiring(acquiring, getState, dispatch) {
  const item = getState().orgCreate.select;
  const index = item.organizationAcquiring
    .map((i) => i.id)
    .indexOf(acquiring.id);

  item.organizationAcquiring.splice(index, 1);

  dispatch({ type: SELECT_ORGANIZATIONS, payload: item });
}
