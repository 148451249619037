import React from "react";
import { connect } from "react-redux";
import {
  Comment,
  ConfirmButton,
  Login,
  Password,
  Token,
  TypeAcquiring,
  UrlCallback,
  Tid,
  CertKey,
  FirstComponent,
  MerchantName,
  Merchant,
  SBPId,
  SbpAccountNumber,
  SBPMerchant,
  SecondComponent,
  Terminal
} from "./components";
import { Message } from 'primereact/message';

function ContentAdd(props) {
  
  const _acquiring = props.acquiring.filter((_item) => _item.id === +props.acquiringId);

  return (
    <div className={props.className}>
      <TypeAcquiring className="p-col-12" />
      <Token className="p-col-12" />
      <UrlCallback className="p-col-12" />

      <Login className="p-col-12 p-md-6" />
      <Password className="p-col-12 p-md-6" />

      {(!props.select && _acquiring.length) && (
        <>
          {_acquiring[0].name === "SberQR" && (
            <>
              <Tid className="p-col-12 p-md-4" />
              <CertKey className="p-col-12 p-md-8" />
              <Message 
                className="p-col-12"
                severity="info" 
                text="Добавление сертификата будет доступно в детальном просмотре 'Эквайринга' после создания."
                closable={false}
              />
            </>
          )}
        </>
      )}

      {(!props.select && _acquiring.length) && (
        <>
          {_acquiring[0].name === "PSB" && (
            <>
              <SBPId className="p-col-12 p-md-4" />
              <Terminal className="p-col-12 p-md-8" />
              <Merchant className="p-col-12 p-md-6 p-xl-4" />
              <MerchantName className="p-col-12 p-md-6 p-xl-4" />
              <SBPMerchant className="p-col-12 p-md-6 p-xl-4" />
              <SbpAccountNumber className="p-col-12 p-md-6 p-xl-4" />
              <FirstComponent className="p-col-12 p-md-6 p-xl-4" />
              <SecondComponent className="p-col-12 p-md-6 p-xl-4" />
            </>
          )}
        </>
      )}

      <Comment className="p-col-12" />

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-end p-p-0">
        <ConfirmButton className="p-col-12 p-md-6" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  acquiring: state.requisites.acquiring,
  acquiringId: state.orgAcquiring.acquiring.acquiringId,
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps)(ContentAdd);
