import React from "react";
import { connect } from "react-redux";
import {
  Comment,
  ConfirmButton,
  BindCashboxBtn,
  DeleteButton,
  Login,
  Password,
  Token,
  TypeAcquiring,
  UrlCallback,
  Tid,
  CertKey,
  Cert,
  FirstComponent,
  MerchantName,
  Merchant,
  SBPId,
  SbpAccountNumber,
  SBPMerchant,
  SecondComponent,
  Terminal,
  CertId
} from "./components";

function ContentEdit(props) {

  return (
    <div className={props.className}>
      <TypeAcquiring className="p-col-12" />
      <Token className="p-col-12" />
      <UrlCallback className="p-col-12" />

      <Login className="p-col-12 p-md-6" />
      <Password className="p-col-12 p-md-6" />

      {props.select?.acquiring?.name === "SberQR" && (
        <>
          <Tid className="p-col-12 p-md-4" />
          <CertKey className="p-col-12 p-md-8" />
          <CertId className="p-col-12"/>
          <Cert className="p-col-12"/>
        </>
      )}

      {props.select?.acquiring?.name === "PSB" && (
        <>
          <SBPId className="p-col-12 p-md-4" />
          <Terminal className="p-col-12 p-md-8" />
          <Merchant className="p-col-12 p-md-6 p-xl-4" />
          <MerchantName className="p-col-12 p-md-6 p-xl-4" />
          <SBPMerchant className="p-col-12 p-md-6 p-xl-4" />
          <SbpAccountNumber className="p-col-12 p-md-6 p-xl-4" />
          <FirstComponent className="p-col-12 p-md-6 p-xl-4" />
          <SecondComponent className="p-col-12 p-md-6 p-xl-4" />
        </>
      )}

      <BindCashboxBtn />

      <Comment className="p-col-12" />

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-end p-p-0">
        <DeleteButton className="p-col-12 p-md-6 " />
        <ConfirmButton className="p-col-12 p-md-6" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps)(ContentEdit);
